var site_config = site_config || {};

var scThankyou = {
	post:          {},
	showClicksnet: false,
	getQueryParam: function(name) {
		var match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		if (match) return decodeURIComponent(match[1].replace(/\+/g, ' '));
	},

	getStep: function(step) {
		var url = '',
			p = {
				class: 'credit-repair',
				title: 'COMPLETE INQUIRY'
			},
			data = {
				first_name:    'first_name',
				firstname:     'first_name',
				last_name:     'last_name',
				lastname:      'last_name',
				email:         'email',
				address:       'address',
				street:        'address',
				city:          'city',
				state:         'state',
				zipcode:       'zipcode',
				zip:           'zipcode',
				evening_phone: 'phone_primary',
				daytime_phone: 'phone_primary',
				phone:         'phone_primary',
				phone2:        'phone_primary'
			};

		step = step || {};

		switch (step.type) {
			case 'dema':
				url = 'http://asntrk.com/?a=346&c=3081&s1=&s2=' + scThankyou.site_id + '&s3=' + scThankyou.ts;
				break;

			case 'credit-score':
				url = 'http://ratelocktrk.com/?a=100&c=4205';
				p = {
					class: 'credit-score',
					title: 'GET MY CREDIT SCORES NOW'
				};
				break;

			case 'custom':
				url = step.url;
				p = {
					class: step.class || 'credit-score',
					title: step.title || ''
				};
				break;

			case 'efolk':
			/* falls through */
			default:
				url = 'http://ratelocktrk.com/?a=100&c=2013&s1=' + scThankyou.ts + '&s2=' + scThankyou.site_id + '&sid=' + scThankyou.ts + '&sid2=' + scThankyou.site_id;
				break;
		}

		for (var d in data) {
			url += '&' + d + '=' + (encodeURIComponent(scThankyou.post[data[d]] || ''));
		}

		url += '&c2=' + (scThankyou.post.id || '');

		$.extend(step, p);

		return $('<a target="_blank"></a>')
			.html(step.title)
			.addClass(step.class)
			.attr('href', url)
			.click(function() {
				ga('send', 'event', 'outbound click', step.class);
			});
	},

	buildTemplate: function() {
		var $template = $('#' + (site_config.redirect.template || 'free360') + '-template'),
			step1Content,
			step2Content;

		if (site_config.steps[1]) {
			// Custom steps can be defined
			step1Content = scThankyou.getStep(site_config.steps[1]);
			step2Content = scThankyou.getStep(site_config.steps[2]);
		} else {
			// Legacy support for old syntax using `site_config.steps.step2`
			var step = {
				type: 'credit-score'
			};
			step1Content = scThankyou.getStep(step);
			step2Content = scThankyou.getStep(site_config.steps.step2);
		}

		$template.find('#sc-step-1').append(step1Content);
		$template.find('#sc-step-2').append(step2Content);
		$template.show();
	},

	initializeHeader: function() {
		if (site_config.hideHeader) {
			$('.page-block:first').hide();
		} else {
			$('.page-block:first img').attr('src', site_config.logo);
		}
	},

	initializeClicksnet: function() {
		var affcid = '1064054',
			key = 'ybWJODkxs581',
			zip = scThankyou.post.zipcode,
			credit_rating = scThankyou.post.credit_rating,
			loan_purpose = scThankyou.post.loan_purpose;

		if (loan_purpose === 'Refinance' && (credit_rating === 'good' || credit_rating === 'excellent')) {
			affcid = "1072062";
			key = "hY13n3BkTBk1";
		}

		//	 clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
		//	 clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
		//	 clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
		var clicksnet_credit_rating = '';// Leave blank (to maximize impressions)
		var clicksnet_loan_range = '';// Leave blank (to maximize impressions)
		var clicksnet_loan_type_term = loan_purpose === 'Purchase' ? 'Purchase%20-%20Fixed%20Rate%20-%2010%20Year' : 'Refinance%20-%20Fixed%20Rate%20-%2010%20Year';

		var subid2 = '';
		subid1 = site_config.clicksnet.subid2 || scThankyou.ts;

		var showHeader = false,
			showWidget = false,
			showFooter = false;


		var clicksnetUrl = '//cdn.fcmrktplace.com/listing/?gh=true&ghs=true&affcamid=' + affcid + '&key=' + key + '&subid1=' + subid1 + '&subid2=' + subid2 + '&zip=' + zip + '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range + '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader + '&showWidget=' + showWidget + '&showFooter=' + showFooter;

		if (($template = $('#clicksnet-template')).length) {
			$.getScript(clicksnetUrl, function() {
				var html = typeof clicksnet_listing_content === 'undefined' ? '' : clicksnet_listing_content.content;

				if ($(html).find('#clicksnet_listings-container-parent').children().length) {
					$template.append($('<div/>').attr('id', 'clicksnet-listing').append(stripAndExecuteScript(html)));
					clicksNetAddExpandButtonListeners();
					$('.sc-template').hide();
					$template.show();
					scThankyou.showClicksnet = true;
				}
			});
		}
	},

	configureSite: function() {
		site_config.hideHeader = scThankyou.getQueryParam('hide_header');

		site_config = $.extend(true, {
			redirect:  {},
			clicksnet: {
				subid2: ''
			},
			steps:     {}
		}, site_config);
	},

	extendWithQueryParams: function() {
		queryParams = {
			site_id: scThankyou.getQueryParam('site_id') || '',
			ts:      scThankyou.getQueryParam('ts') || '',
			post: {
				zipcode:            scThankyou.getQueryParam('zipcode') || scThankyou.getQueryParam('property_zip'),
				credit_rating:      scThankyou.getQueryParam('credit_rating'),
				loan_purpose:       scThankyou.getQueryParam('loan_purpose'),
				est_purchase_price: scThankyou.getQueryParam('est_purchase_price'),
				mortgage_amount:    scThankyou.getQueryParam('mortgage_amount')
			}
		};
		return $.extend(true, scThankyou, queryParams);
	},

	formLoaded: function() {
		$('body').addClass('is-loaded');
	},

	initialize: function() {
		scThankyou = scThankyou.extendWithQueryParams();
		scThankyou.configureSite();
		scThankyou.initializeHeader();
		scThankyou.initializeClicksnet();
		if (!scThankyou.showClicksnet) scThankyou.buildTemplate();
		scThankyou.formLoaded();
	}
};

(function() { scThankyou.initialize(); })();

